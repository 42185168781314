import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { stageActions, content_groupActions } from '../../../actions';
import '@toast-ui/calendar/dist/toastui-calendar.css';
import ContentGroupContent  from '../WorkSpacePage/contentGroupForm';
import { change, reset } from "redux-form";
import { adminBaseURL } from "../../../utils/commonUtils";
import { useIsVisible } from '../../../utils/useIsVisible';
import { WorkspaceShowContext } from "../../../contexts/WorkspaceShowContext";
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../../../constants';
import DesktopCalendar from './desktop/desktopCalendar';
import MobileCalendar from './mobile/mobileCalendar';
import { useDetectOutsideClick } from "../../../utils/useDetectOutsideClick";
import ContentLabelPopup from '../ContentLabelPopup/list';
import { createCancelToken } from '../../../utils/Axios';

function WorkspaceCalendar(props) {
  const dispatch = useDispatch();
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.MEDIUM}px)` });
  const {
    workspace_id,
    stages,
    projects,
    created_content_group,
    destroyed_content_group,
    updated_content_group,
    currentSearchText,
    can_access_edit_label,
    schedule,
    workspace_view,
    content_group_loading,
    draft_status
  } = props
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const [scheduleList, setScheduleList] = useState([]);
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });
  const custom_close_button_id_text = 'calendar_label-';
  const scheduleRef = useRef();
  scheduleRef.current = scheduleList;

  const {
    can_access_content,
    handleOpenContentGroupPopUp,
    handleOpenLabelDrpdwn,
    drpdwnPosition,
    labelDrpdwnValues,
    setLabelDrpdwnValues,
    loadTracker,
    query,
    showMobileLabelDrpdwn
  } = useContext(WorkspaceShowContext);

  const loadTrackerRef = useRef();
  loadTrackerRef.current = loadTracker;

  useEffect(() => {
    handleCloseLabelDrpdwn();
  }, [isLargeScreen]);

  const handleCloseLabelDrpdwn = () => {
    setShowLabelDrpdwn((prevState) => false);
    setLabelDrpdwnValues({
      content_group_id: null,
      labels: []
    })
  };

  let labelDrpdwnContainerRef = useRef();
  // The useDetectOutsideClick detects two events when clicking at calendar event labels preventing from opening popup
  let [showLabelDrpdwn, setShowLabelDrpdwn] = useDetectOutsideClick(labelDrpdwnContainerRef, false);

  let calendarRef = useRef(null);
  let calendarTabRef = useRef()
  const isVisible = useIsVisible(calendarTabRef);
  const initialLoaded = useRef(false);
  const [date, setDate] = useState(Date().toLocaleString());

  function updateDraftStatus(obj, rows){
    const { stage_id, content_group_id, partner_draft, internal_draft } = obj;
    let rowDup = [...rows];

    let content_group_index = rowDup.findIndex(content_group => content_group.id === content_group_id );

    if(content_group_index !== -1){
      rowDup[content_group_index].drafts = [partner_draft, internal_draft];

      setScheduleList(rowDup);
    }
  }

  useEffect(() => {
    if(draft_status && !loadTrackerRef.current){
      updateDraftStatus(draft_status, scheduleRef.current)
    }
    return () => {
      
    };
  }, [draft_status]);

  const toLocaleDate = (date_param) =>{
    var date = new Date(date_param)
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  }

  const addSchedule = (new_schedule = []) => {
    let new_schedule_ids = new_schedule.map((s) => s.id);
    let old_schedule_list = scheduleList
    let new_schedule_list = [...scheduleList.filter(s => !new_schedule_ids.includes(s.id)), ...new_schedule]
    setScheduleList(new_schedule_list)

    const old_array = old_schedule_list.map((o) => removeKeys(o));
    const new_array = new_schedule_list.map((o) => removeKeys(o));

    if(JSON.stringify(old_array) !== JSON.stringify(new_array)){
      closeOverlayPopup();
    }
  }

  const removeSchedule = (remove_schedule = {}) => {
    let old_schedule_list = scheduleList
    let new_schedule_list = scheduleList.filter(s => s.id !== remove_schedule.id )
    setScheduleList(new_schedule_list)

    if(JSON.stringify(new_schedule_list) !== JSON.stringify(old_schedule_list)){
      closeOverlayPopup();
    }
  }

  const removeKeys = (object) => {
    const { assigned_users, preview, first_record, ...rest } = object;

    return rest;
  }

  const replaceSchedule = (new_schedule = []) => {
    setScheduleList(prevState => new_schedule)
  }

  useEffect(() => {
    let api_date = toLocaleDate(Date().toLocaleString()).toJSON()
    const calendarCallback = (new_schedule = []) =>{
      replaceSchedule(new_schedule);
    }
    const search = query.get('search');
    const cancelTokenSource = createCancelToken();

    dispatch(stageActions.getCalendarData(workspace_id, api_date, search, cancelTokenSource.token, calendarCallback))
    initialLoaded.current = true

    return () => {
      cancelTokenSource.cancel('Operation canceled by the user');
    }
  }, []);

  useEffect(() => {
    if(updated_content_group){
      if (updated_content_group.id === labelDrpdwnValues.content_group_id){
        setLabelDrpdwnValues((prevState) => ({ ...prevState, labels: updated_content_group?.labels }))
      }
    }

  }, [updated_content_group])

  useEffect(() => {
    if(workspace_view === 'calendar'){
      if(calendarRef.current){
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.render();
        calendarInstance.setDate(date);
      }
    }
  }, [workspace_view]);

  useEffect(() => {
    if(updated_content_group){
      let dupList = scheduleList;
      let index = dupList.findIndex((element) => element.id == updated_content_group.id);

      if ( index !== -1){
        dupList[index] = updated_content_group

        setScheduleList(prevState => dupList)
        closeOverlayPopup();
      }
    }
  }, [updated_content_group]);

  useEffect(() => {
    if(isVisible){
      let api_date = toLocaleDate(date).toJSON()
      const calendarCallback = (new_schedule = []) => {
        if (currentSearchText){
          replaceSchedule(new_schedule)
        } else {
          addSchedule(new_schedule)
        }
      }
      const cancelTokenSource = createCancelToken();

      dispatch(stageActions.getCalendarData(workspace_id, api_date, currentSearchText, cancelTokenSource.token, calendarCallback))

      return () => {
        cancelTokenSource.cancel('Operation canceled by the user');
      }
    }

  }, [date, created_content_group, loadTracker, isVisible, currentSearchText]);

  useEffect(() => {
    if(destroyed_content_group){
      removeSchedule(destroyed_content_group)
    }
  }, [destroyed_content_group]);

/*
  useEffect(() => {
    if(isVisible && initialLoaded.current && loadTracker){
      let api_date = toLocaleDate(date).toJSON();
      const calendarCallback = (new_schedule = []) => {
        addSchedule(new_schedule)
      }
      const cancelTokenSource = createCancelToken();

      dispatch(stageActions.getCalendarData(workspace_id, api_date, currentSearchText, cancelTokenSource.token, calendarCallback));

      return () => {
        cancelTokenSource.cancel('Operation canceled by the user');
      }
    }
  }, [loadTracker, isVisible]);
*/

/*
  useEffect(() => {
    initialLoaded.current = false
    if (isVisible) {
      let api_date = toLocaleDate(date).toJSON()
      const calendarCallback = (new_schedule = []) =>{
        replaceSchedule(new_schedule)
      }
      const search = query.get('search');
      const cancelTokenSource = createCancelToken();

      dispatch(stageActions.getCalendarData(workspace_id, api_date, search, cancelTokenSource.token, calendarCallback))
      initialLoaded.current = true


      return () => {
        cancelTokenSource.cancel('Operation canceled by the user');
      }
    }
  }, [currentSearchText]);
*/

  const handleClickRenderButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.render();
  }

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.today();
    setDate(calendarInstance.getDate().d.toLocaleString());
  }

  const onClickSchedule = useCallback((e) => {
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const { target, clientX, clientY } = e.nativeEvent
    const { calendarId, id, raw } = e.event;
    //const el = calendarRef.current.calendarInst.getElement(id, calendarId);

    if (target.classList.contains('content-label__close-button') && can_access_edit_label){
      let target_id = target.id
      let label_id = target_id.split('-')[1];

      let requestParams = { label_id: label_id, assigned: false }
      let callback = () => {}
      let content_popup_notification = undefined;

      dispatch(content_groupActions.assignContentLabelToContentGroup(workspace_id, id, requestParams, content_popup_notification, callback))

    } else if (target.classList.contains('content-label') && can_access_edit_label){
      let target_id = target.id
      let label_id = target_id.split('-')[1];

      handleOpenLabelDrpdwn(target, id, raw.labels, labelDrpdwnContainerRef, showLabelDrpdwn, setShowLabelDrpdwn)
    } else if (target.classList.contains('content-label__text') ) {
      handleOpenContentGroupPopUp(id);
    }
  });

  const closeOverlayPopup = () => {
    const popup_overlay = document.getElementsByClassName('toastui-calendar-popup-overlay')[0]

    if(popup_overlay){
      popup_overlay.click();
    }
  }

  const [shopContentForm, setShopContentForm] = useState(false)
  const [dueDate, setDueDate] = useState(false)
  const onBeforeCreateSchedule = function(event) {
    if (can_access_content) {
      dispatch(change("content_group_form", "due_date", event.end))
      setDueDate(event.end)
      setShopContentForm(true)
    }
  }

  const handleCloseForm = () => {
    setShopContentForm(false)
    const calendarInstance = calendarRef.current?.getInstance();

    dispatch(reset('content_group_form'));
    if(calendarInstance){
      calendarInstance.clearGridSelections();
    }
  }

  const handleSeeMoreEvents = (date) =>{
    const { top, height, left } = date.target.getBoundingClientRect();

    const { innerWidth, innerHeight } = window;
    const padding = top - innerHeight;

    if(top < 0){
      return date.target.style.top = '51px';
    }

    if(innerHeight <= top + height){
      const beta_sum = top - (height + padding + 35);
      if(beta_sum >0) {
        return date.target.style.top = beta_sum + 'px';
      } else{
        return date.target.style.top = top - (height) + 'px';
      }
    }
  }

  const showResults = (values) => {
    dispatch(content_groupActions.createContentGroup(values, workspace_id, handleCloseForm));
  }

  return (
      <div className="d-flex flex-column" style={{ height: "100%" }} ref={calendarTabRef}>
        {
          workspace_view === 'calendar' &&
            isMediumScreen ?
              <MobileCalendar
                onBeforeCreateSchedule={onBeforeCreateSchedule}
                workspace_id={workspace_id}
                setDate={setDate}
                defaultActiveStartDate={date}
                calendarRef={calendarRef}
                events={scheduleList}
                can_access_content={can_access_content}
                can_access_edit_label={can_access_edit_label}
                setShopContentForm={setShopContentForm}
                labelDrpdwnContainerRef={labelDrpdwnContainerRef}
                showLabelDrpdwn={showLabelDrpdwn}
                setShowLabelDrpdwn={setShowLabelDrpdwn}
              />
            :
              <>
                <DesktopCalendar
                  workspace_id={workspace_id}
                  setDate={setDate}
                  date={date}
                  onSelectDateTime={onBeforeCreateSchedule}
                  calendarRef={calendarRef}
                  events={ scheduleList }
                  can_access_content={can_access_content}
                  onClickEvent={onClickSchedule}
                  custom_close_button_id_text={custom_close_button_id_text}
                  can_access_edit_label={can_access_edit_label}
                />
              </>
        }


        { shopContentForm &&
          <ContentGroupContent
            stages={stages}
            onSubmit={showResults}
            dueDateInitialize={dueDate && dueDate}
            handleCloseForm={handleCloseForm}
          />
        }

        <nav
          className={`content-label-popup vertical-scroll_thin ${ showLabelDrpdwn ? "active" : "inactive"}`}
          ref={labelDrpdwnContainerRef}
          style={drpdwnPosition}
        >
          <ContentLabelPopup
            labels={ labelDrpdwnValues.labels }
            workspaceId={ workspace_id }
            contentGroupId={ labelDrpdwnValues.content_group_id }
            handleClose={ () =>  handleCloseLabelDrpdwn() }
            can_access_edit_label={can_access_edit_label}
            automaticClose={true}
            show={showMobileLabelDrpdwn}
          />
        </nav>
      </div>
  );
}


const mapStateToProps = (state) => {
  return {
    content_group_loading: state.content_group.content_group_loading,
    loading: state.work_space.loading,
    created_content_group: state.content_group.created_content_group,
    updated_content_group: state.content_group.updated_content_group,
    destroyed_content_group: state.content_group.destroyed_content_group,
    schedule: state.stage.schedule,
    currentSearchText: state.search_history.current_search_text,
    workspace_view: state.work_space.workspaceView,
    draft_status: state.content_group.draft_status,
  };
};

export default connect(mapStateToProps, {  })(WorkspaceCalendar);
