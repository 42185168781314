import { useRef, useState, useEffect, useCallback } from "react";
import { Form, InputGroup, CloseButton } from "react-bootstrap";
import { ListItemChecked, ListItemUnchecked } from "../icons";
import { useDetectOutsideClick } from "../../utils/useDetectOutsideClick";
import { useDetectOutsideDoubleClick } from "../../hooks/useDetectOutsideDoubleClick";
import { selectAllText } from "../../utils";
import { listItemsActions } from "../../actions";
import { useContext } from "react";
import { ContentGroupContext } from "../../providers/ContentGroupProvider";
import { useDispatch, useSelector } from "react-redux";
import { buildFormDataFromObject } from "../../utils/commonUtils";
import { AutoLink } from "../AutoLink";

function SubtaskListItem({
  item
}) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(item?.done)
  const input_ref = useRef();

  const [description, setDescription] = useState(item?.description);
  const initialLoaded = useRef(false);
  const  {workspace_id, content_group_id } = useContext(ContentGroupContext);
  const can_update = useSelector(state => state.list.can_update);
  const can_destroy = useSelector(state => state.list.can_destroy);
  
  const enableContentEditable = useCallback((e) => {
    setTimeout(() => {
      if(input_ref.current){
        input_ref.current.focus();
      }
    }, 0);
  }, [input_ref]);

  const { doubleClick, setIsActive, isActive } = useDetectOutsideDoubleClick(enableContentEditable, input_ref);


  function handleDelete(){
    dispatch(listItemsActions.destroyListItem({workspace_id, content_group_id, list_id: item?.list_id, list_item_id: item.id }))
  }

  async function handleSubmit(formData, options = {}){
    const values = {
      list_item_data: formData, 
      workspace_id, 
      content_group_id, 
      list_id: item?.list_id,
      list_item_id: item?.id,
      ...options
    }

    return dispatch(listItemsActions.updateListItem(values));
  }

  function handleKeyPress(e){

    if(['Enter'].includes(e.key)){
      e.preventDefault();
      setIsActive(false);
    }
  }

  useEffect(() => {
    if(!isActive && initialLoaded.current){
      let formData = new FormData();
      formData.description =  input_ref.current.value
      buildFormDataFromObject(formData, { description:  input_ref.current.value }, "list_item");

      const onError = () => {
        setDescription(item?.description);
        input_ref.current.value = item?.description;
      }

      handleSubmit(formData, {onError})
    }

    initialLoaded.current = true;

    return () => {
      
    };
  }, [isActive]);

  useEffect(() => {
    if(item){
      if(description !== item?.description){
        setDescription(item?.description)
      }

      if(checked !== item?.done){
        setChecked(item?.done)
      }

    }
    return () => {
      
    };
  }, [item]);


  const handleChecked = (e) =>{
    const done = !checked;
    let formData = new FormData();
    formData.done =  done;

    setChecked(done);
    buildFormDataFromObject(formData, { done:  done }, "list_item");
    
    const onError = () => {
      setChecked(!done)
    }

    handleSubmit(formData, {onError})
  }

  return (
    <div className="d-flex align-items-center  subtask-item mb-2">
      <InputGroup.Text 
        className={`${can_update ? 'cursor-pointer' : ''} subtask-checkbox`} 
        onClick={(e) => can_update ? handleChecked(e) : null}
      >
        {
          checked ?
            <ListItemChecked  />
          : 
            < ListItemUnchecked/>
        }
      </InputGroup.Text>
      <Form className={`w-100 subtask-item-form  ${isActive ? 'subtask-item-form_active subtask-item-title' : null}`}>
        <div className="position-relative">
          <Form.Control
            placeholder="Add Subtask Title"
            type="text"
            ref={input_ref}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`subtask-item-description ${isActive ? '' : 'd-none'}`}
            onKeyDown={(e) => can_update ? handleKeyPress(e) : null}
          />

          <div
            className={`subtask-item-description ${ checked ? 'subtask-done' : '' } ${isActive ? 'd-none' : ''}`}
            onClick={(e) => can_update ? doubleClick(e) : null}
          >
            <AutoLink text={description}/>
          </div>
          
          {
            can_destroy &&
            <CloseButton onClick={handleDelete}/>
          }
          
        </div>
      </Form>


    </div>
  );
}

export { SubtaskListItem };
