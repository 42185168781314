import { alertActions } from './';
import { listConstants } from '../constants';
import { 
  makeGETRequest, 
  makeFormDataPOSTRequest, 
  makeDELETERequest, 
  makeFormDataPUTRequest, 
  controlledGETRequest, 
  createCancelToken 
} from '../utils/Axios';

export const listItemsActions = {
  createListItem,
  updateListItem,
  destroyListItem
};

export function createListItem(props) {
  const {
    list_item_data, 
    workspace_id, 
    content_group_id, 
    list_id
  } = props;

  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/lists/${list_id}/list_items`, { list_item: list_item_data })
      .then(response => {
        if(response.data.status === "ok"){
          //dispatch(alertActions.success(listConstants.LIST_ITEM_CREATED));
          dispatch({type: listConstants.LIST_ITEM_CREATED, payload: response.data})
        }else {
          dispatch(alertActions.error(response.data.message));
          
          dispatch({ type: listConstants.LIST_ITEM_FAILURE, payload: response.data.message})
        }
      })
    }catch(e){
      dispatch( {
        type: listConstants.LIST_ITEM_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateListItem(props) {
  const {
    list_item_data, 
    workspace_id, 
    content_group_id, 
    list_id,
    list_item_id,
  } = props;

  return function (dispatch) {
    try{
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/lists/${list_id}/list_items/${list_item_id}`, { list_item: list_item_data })
      .then(response => {
        if(response.data.status === "ok"){
          //dispatch(alertActions.success(listConstants.LIST_ITEM_UPDATED));
          dispatch({type: listConstants.LIST_ITEM_UPDATED, payload: response.data})
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: listConstants.LIST_ITEM_FAILURE, payload: response.data.message});

          props?.onError();
        }
      })
    }catch(e){
      dispatch( {
        type: listConstants.LIST_ITEM_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyListItem(props) {
  const { workspace_id, content_group_id, list_id, list_item_id } = props;

  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/lists/${list_id}/list_items/${list_item_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          //dispatch(alertActions.success(listConstants.LIST_ITEM_DELETED));
          dispatch({type: listConstants.LIST_ITEM_DELETED, payload: response.data})
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: listConstants.LIST_ITEM_FAILURE, payload: response.data.message})
        }
      }).finally(() => {
        props?.onEnd?.();
      })
    }catch(e){
      dispatch( {
        type: listConstants.LIST_ITEM_FAILURE,
        payload: console.log(e),
      })
    }
  }
}