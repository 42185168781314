import { Button, Form } from "react-bootstrap";
import { SubtaskIcon } from "../icons";
import { useRef, useState, useCallback, useEffect, useContext } from "react";
import { SubtaskListItem, AddSubtaskInput, ListProgressBar } from "./";
import { useDetectOutsideDoubleClick } from "../../hooks/useDetectOutsideDoubleClick";
import { Popup } from "../Popup";
import { selectAllText } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { listActions } from "../../actions/list.actions";
import { ContentGroupContext } from "../../providers/ContentGroupProvider";
import { buildFormDataFromObject } from "../../utils/commonUtils";

function SubtaskList({
  list
}) {
  const dispatch = useDispatch();
  const [subtask_title, setSubtaskTitle] = useState(list?.title);
  const initialLoaded = useRef(false);
  const input_ref = useRef();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const { workspace_id, content_group_id } = useContext(ContentGroupContext);
  const [hidden, setHidden] = useState(list?.hidden || false);
  const can_create = useSelector(state => state.list.can_create);
  const can_update = useSelector(state => state.list.can_update);
  const can_destroy = useSelector(state => state.list.can_destroy);

  const enableContentEditable = useCallback((e) => {
    setTimeout(() => {
      if(input_ref.current){
        input_ref.current.focus();
      }
    }, 0);
  }, [input_ref]);

  const { doubleClick, setIsActive, isActive } = useDetectOutsideDoubleClick(enableContentEditable, input_ref);

  function handleClosePopup(){
    setShowDeletePopup(false)
  }

  function handleDelete(){
    const onEnd = () => {
      handleClosePopup();
    }

    dispatch(listActions.destroyList({workspace_id, content_group_id, list_id: list?.id, onEnd }))
  }

  async function handleSubmit(formData, options = {}){
    const values = {
      formData, 
      workspace_id, 
      content_group_id, 
      list_id: list?.id,
      ...options
    }

    return dispatch(listActions.updateList(values));
  }

  function handleKeyPress(e){
    
    if(['Enter'].includes(e.key)){
      e.preventDefault();
      setIsActive(false);
    }
  }

  function handleVisibilityUpdate(){
    const visibility = !hidden;

    let formData = new FormData();
    formData.hidden =  visibility;
    setHidden(visibility);
    buildFormDataFromObject(formData, { hidden:  visibility }, "list");
    
    const onError = () => {
      setHidden(!visibility)
    }

    handleSubmit(formData, {onError})
  }

  useEffect(() => {
    if(!isActive && initialLoaded.current){
      let formData = new FormData();
      formData.title =  input_ref.current.value
      buildFormDataFromObject(formData, { title:  input_ref.current.value }, "list");

      const onError = () => {
        setSubtaskTitle(list?.title);
        input_ref.current.value = list?.title;
      }
  
      handleSubmit(formData, {onError})
    }
    initialLoaded.current = true;

    return () => {
      
    };
  }, [isActive]);

  useEffect(() => {
    if(list){
      if(subtask_title !== list?.title){
        setSubtaskTitle(list?.title)
      }
    }

    return () => {
      
    };
  }, [list]);

  return (
    <div className="subtask mb-3 pb-3">
      {
        showDeletePopup &&
          <Popup
            handleClose={handleClosePopup}
            handleDelete={handleDelete}
            display_text={'Are you sure?'}
          />
      }

      <div 
        className={`subtask-form mb-1 ${ isActive ? 'subtask-form-input_active' : 'subtask-form-title'}`}
      >
        <div className="d-flex align-items-center">
            <div className="d-flex align-items-center w-100">
              <div className="subtask-form-icon d-flex align-items-center" ><SubtaskIcon/></div>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Form.Control
                  placeholder="Add List Title"
                  ref={input_ref}
                  className={`${isActive ? '' : 'd-none'} h-100 subtask-title w-100`}
                  value={subtask_title}
                  onChange={(e) => setSubtaskTitle(e.target.value)}
                  onKeyPress={(e) => can_update ? handleKeyPress(e) : null}
                  type="text"
                />

                <div
                  className={`h-100 subtask-title w-100 ${isActive ? 'd-none' : ''}`}
                  onClick={(e) => can_update ? doubleClick(e) : null}
                >
                  {subtask_title}
                </div>
              </div>  
            </div>

            {
                !isActive && (can_update || can_destroy) &&
                  <div className="d-flex justify-content-end subtask-buttons">
                    {
                      can_update && 
                      <Button 
                        variant={'subtask'}
                        className={"btn-subtask-visibility"} 
                        onClick={handleVisibilityUpdate}
                      > 
                        {
                          hidden ? 
                            'Show '
                          : 
                            'Hide '
                        } 
                        to Guests
                      </Button>
                    }

                    {
                      can_destroy && 

                      <Button 
                        variant={'subtask'}
                        className={"btn-subtask-delete"} 
                        onClick={() => setShowDeletePopup(true)}
                      > 
                        Delete
                      </Button>
                    }

                  </div>

              }          
        </div>
      </div>

      <ListProgressBar list={list}/>

      {
        list?.items.map((item, index) => (
          <SubtaskListItem 
            item={item} 
            key={'list_item_'+item.id}
          />
        ))
      }
      {
        can_create && 
        <AddSubtaskInput list={list}/>
      }
    </div>
  );
}

export { SubtaskList };
