import { useState, useEffect, useRef } from "react";
import {useDispatch, useSelector } from 'react-redux';
import { userConstants } from "../constants";
import { commentActions } from "../actions";
import { buildFormDataFromObject } from "../utils/commonUtils";

export const useCommentDraftHooks = ({ 
  activeTab, 
  workspace_id, 
  commentToReplyId, setCommentToReplyId, 
  uploadedFiles, setUploadedFiles, 
  commentToEdit, setCommentToEdit,
  upload_file_input,
  content_group_id, 
  chat_input,
  update_draft_token, 
  resetDraftToken,
  update_draft_timer,
  update_draft_attachment_timer,
  chatText, setChatText  
}) => {
  const dispatch = useDispatch();
  const isDraftLoadedRef = useRef(false);
  const redux_draft_comment = useSelector(state => state.comment.draft_comment);
  const draft_comment_loading = useSelector(state => state.comment.draft_comment_loading);
  const [draft_comment, setDraftComment] = useState(redux_draft_comment);
  const draft_comment_ref = useRef(null);
  const uploaded_files_ref = useRef([]);
  const comment_to_edit_ref = useRef();
  const draft_description_ref = useRef();
  const attachment_ref = useRef([]);
  const activeTabRef = useRef();
  const draftCommentLoadingRef = useRef();
  const initialLoadedRef = useRef(false);
  const commentToReplyIdRef = useRef();

  activeTabRef.current = activeTab;
  draftCommentLoadingRef.current = draft_comment_loading;
  commentToReplyIdRef.current = commentToReplyId;

  // Be careful when using deleteDraft(). Loop might happen
  function deleteDraft(){
    const filtered_attachments = attachment_ref.current?.filter((attachment) => attachment?.id ? attachment._destroy === undefined : true )

    if(
      (draft_description_ref.current?.trim() === '' || draft_description_ref.current === null)  
      && filtered_attachments.length === 0 
      && commentToReplyIdRef.current === null 
      && (comment_to_edit_ref.current === null || comment_to_edit_ref.current === undefined ) 
      && draft_comment_ref.current?.id 
    ){
      const successCallback =()=>{
        const ignore_clear_draft = true;
        resetDraft(ignore_clear_draft);
      }
      const internal = activeTabRef.current === 'internal_notes';
      dispatch(commentActions.destroyDraft(workspace_id, content_group_id, draft_comment_ref.current?.id, internal, successCallback))
    }
  }

  useEffect(() => {
    initialLoadedRef.current = true;

    return () => {
      if(draft_comment_ref.current?.id && (comment_to_edit_ref.current === null || comment_to_edit_ref.current === undefined )){
        const { ...objValues} = draft_comment_ref.current;
        objValues.description = draft_description_ref.current;
        objValues.parent_id = commentToReplyId || '';
        objValues.attachments_attributes = attachment_ref.current;
    
        const callback = (res) => {
          resetDraft();  
        } 
    
        submitDraft(objValues, callback)
      }
    }
  }, []); // Reset draft if component unmounted

  // uploadedFiles, commentToReplyId, and chatText useEffect must be separate or looping happens
  useEffect(() => {
    commentToReplyIdRef.current = commentToReplyId;
    draft_description_ref.current = chatText.trim() === '' ? '' : chat_input?.innerHTML;

    if(initialLoadedRef.current && ( commentToReplyId === null || chatText.trim() === '')){
      deleteDraft();
    }
  }, [commentToReplyId, chatText]);

  useEffect(() => {
    attachment_ref.current = uploadedFiles;
     const for_deletion_attachments = attachment_ref.current?.filter((attachment) => attachment?.id ? attachment._destroy === true : false )

    if(initialLoadedRef.current && uploadedFiles.length > 0 && for_deletion_attachments.length > 0){
      deleteDraft();
    }
  }, [uploadedFiles]);


  useEffect(() => {
    if(redux_draft_comment){
      setupDraft(redux_draft_comment)
    }
  }, [redux_draft_comment]);

  useEffect(() => {
    if(JSON.stringify(mapAttachment(redux_draft_comment?.attachments)) !== JSON.stringify(mapAttachment(uploadedFiles)) &&
      (draft_comment_ref.current === null || draft_comment_ref.current === undefined)
    ){
      setUploadedFiles(redux_draft_comment?.attachments || [])
    }
  }, [redux_draft_comment?.attachments]);

  useEffect(() => {
    comment_to_edit_ref.current = commentToEdit;

    setTimeout(() => {
      if(commentToEdit && draft_comment_ref.current?.id && (commentToEdit?.id !== draft_comment_ref.current?.id)){
        const successCallback =()=>{
          //resetDraft();
        }
        const internal = activeTabRef.current === 'internal_notes';
        dispatch(commentActions.destroyDraft(workspace_id, content_group_id, draft_comment_ref.current?.id, internal, successCallback))
      }
    }, 0);
  }, [commentToEdit]);

  useEffect(() => {
    if(activeTab.current !== ""){
      if (chat_input){
        dispatch({ type: userConstants.COMMENT_TAB_LOADING });
        isDraftLoadedRef.current = false;
        initialLoadedRef.current = false;
        submitSetupDraft(activeTab.current === 'internal_notes')
          .then(() => {
            const callback = (comment) => {
              setupDraft(comment);
            }

            dispatch(commentActions.createDraft(workspace_id, content_group_id, activeTabRef.current, callback)) // Loads the draft of the active tab
          })
          .finally(() => {
            dispatch({ type: userConstants.COMMENT_TAB_LOADED });
            isDraftLoadedRef.current = true;
            initialLoadedRef.current = true;
          });
      }
    }
  }, [activeTabRef.current, chat_input]); 

  async function submitSetupDraft(internal){
    if(draft_comment_ref.current?.id && (comment_to_edit_ref.current === null || comment_to_edit_ref.current === undefined )){
      const { ...objValues} = draft_comment_ref.current;
      objValues.description = draft_description_ref.current;
      objValues.parent_id = commentToReplyIdRef.current || '';
      objValues.attachments_attributes = attachment_ref.current;

      const successCallback = (res) => {
        //setupDraft(res.data.data);
      }
      submitDraft(objValues, successCallback)
    }
  }
  
  /*
  // Update Text and reply id
  useEffect(() => {
    const interval_ms = 1000;
    const similar_tab = redux_draft_comment?.internal ? activeTab === 'internal_notes' : activeTab === 'discussion';

    if (redux_draft_comment === draft_comment_ref.current && similar_tab && isDraftLoadedRef.current){
      update_draft_timer = setTimeout(
          function() {
            if (comment_to_edit_ref.current === null || comment_to_edit_ref.current === undefined){
              const { ...objValues} = redux_draft_comment;
              objValues.description = chatText;
              objValues.parent_id = commentToReplyId;

              const callback = (response) => {
                if(draft_comment_ref.current?.id === response.data.data.id) return; 
                if (chatText !== response.data.data.description ) {
                  setChatText(response.data.data.description)
                }

                if(commentToReplyId !== response.data.data.parent_id){
                  setCommentToReplyId(response.data.data.parent_id)
                }
              }

              submitDraft(objValues, callback);
            }

          }
          .bind(this),
          interval_ms
      );
    }

    isDraftLoadedRef.current = true

    return () => {
      clearTimeout(update_draft_timer);
    }
  }, [chatText, commentToReplyId]);

  // Update attachment
  useEffect(() => {
    const interval_ms = 1000;
    const similar_tab = redux_draft_comment?.internal ? activeTab === 'internal_notes' : activeTab === 'discussion';

    if (redux_draft_comment && similar_tab && uploadedFiles){
      update_draft_attachment_timer =setTimeout(
        function() {
          if (comment_to_edit_ref.current === null || comment_to_edit_ref.current === undefined  ){
            if(JSON.stringify(mapAttachment(uploadedFiles)) !== JSON.stringify(mapAttachment(attachment_ref.current)) ){
              const { description, parent_id, ...objValues} = redux_draft_comment;

              if(upload_file_input) upload_file_input.value = '';
    
              objValues.attachments_attributes = uploadedFiles;

              const callback = (response) => {
                if (
                  JSON.stringify(mapAttachment(response.data.data.attachments)) !== JSON.stringify(mapAttachment(attachment_ref.current)) &&
                  draft_comment_ref.current?.id === response.data.data.id 
                ) {
                  
                  setUploadedFiles(response.data.data.attachments);
                  attachment_ref.current = response.data.data.attachments;
                }
              }
              submitDraftAttachment(objValues, callback);
    
              isDraftLoadedRef.current = false;
            }
          }
        }.bind(this),
        interval_ms
      )

      return () => {
        clearTimeout(update_draft_attachment_timer);
      }
    }

    isDraftLoadedRef.current = true
  }, [uploadedFiles]);
  */

  function submitDraft(objValues, callback){
    let formData = new FormData();

    buildFormDataFromObject(formData, objValues, "comment");  

    const update_param = {
      comment: formData, 
      workspace_id, 
      content_group_id, 
      comment_id: objValues?.id, 
      cancel_token: update_draft_token.token,
      callback
    }

    dispatch(commentActions.updateDraft(update_param));
  }

  function resetDraft(ignore_clear_draft = false) {
    setUploadedFiles([]);
    uploaded_files_ref.current = [];
    isDraftLoadedRef.current = false;
    if(upload_file_input) upload_file_input.value = '';
    resetDraftToken();

    if(ignore_clear_draft || comment_to_edit_ref.current) return;

    setDraftComment(null);
    setChatText('');
    draft_comment_ref.current = null;
    draft_description_ref.current = '';
    if(chat_input) chat_input.innerHTML = '';

    dispatch({ type: userConstants.CLEAR_DRAFT });
  };

  function setupDraft(comment) {
    setDraftComment(comment);
    draft_comment_ref.current = comment;
    if(chat_input) chat_input.innerHTML = comment.description;
    setChatText(comment.description || '');
    draft_description_ref.current = comment.description;
    attachment_ref.current = []

    if(comment.parent){
      setCommentToReplyId(comment.parent_id)
    }
    if(comment.attachments){
      setUploadedFiles(comment.attachments)
    }
  };

  return draft_comment
};

function  mapAttachment (array) {
  if(array === null || array === undefined){
    return []
  }

  return array.map(item => item.id)
}
