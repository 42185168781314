import { useEffect, useRef, useState } from "react";
import { useDetectOutsideClick } from "../../utils/useDetectOutsideClick";
import { ListItemDisabledChecked  } from "../icons";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useContext } from "react";
import { ContentGroupContext } from "../../providers/ContentGroupProvider";
import { buildFormDataFromObject } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { listItemsActions } from "../../actions";

function AddSubtaskInput({
  list
}) {
  const input_ref = useRef();
  const dispatch = useDispatch();
  const [input_active, setInputActive] = useDetectOutsideClick(input_ref, false);
  const can_create = useSelector(state => state.list.can_create)
  const description_ref = useRef('');
  const { workspace_id, content_group_id } = useContext(ContentGroupContext);
  const [description, setDescription] = useState('');

  function handleKeyPress(e){    
    if(['Enter'].includes(e.key)){
      e.preventDefault();
  
      handleSubmit()
        .then(() => {
          resetInput();
        });
    }
  }

  async function handleSubmit(){
    let formData = new FormData();
    formData.description = description_ref.current

    buildFormDataFromObject(formData, { description:  description_ref.current }, "list_item");

    const values = {
      list_item_data: formData,
      workspace_id,
      content_group_id,
      list_id: list?.id
    }

    return dispatch(listItemsActions.createListItem(values));
  }

  function resetInput(){
    description_ref.current = '';
    input_ref.current.innerHTML = '';
    setDescription('');
  }

  useEffect(() => {
    if(!input_active && description_ref.current !== ''){
      handleSubmit().then(() => {
        resetInput();
      });
    }

    if(input_active){
      if(input_ref.current){
        setTimeout(() => {
          input_ref.current.focus();
        }, 0);
      }
    }

    return () => {
      
    };
  }, [input_active]);

  function handleChange(e){
    setDescription(e.target.value);
    description_ref.current = e.target.value;
  }

  return (
    <>
      <div
        className={`subtask-item-form ${input_active ? "subtask-item-form-input_active" : "subtask-item-form-button"}`}
      >
        <div className="d-flex align-items-center">
          <InputGroup.Text className={'subtask-checkbox'}>
            <ListItemDisabledChecked/>
          </InputGroup.Text>        

          <Form.Control
            placeholder="Add Subtask"
            ref={input_ref}
            className={`subtask-item-form-add_item w-100 ${input_active ? '' : 'd-none'}`}
            value={description}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            type="text"
          />

          {
            !input_active && can_create &&
              <Button 
                variant={'subtask'} 
                className="btn-subtask-item-create"
                onClick={() => setInputActive(true)}
              > 
                Add Subtask
              </Button>
          }
        </div>
      </div>
    </>
  );
}

export { AddSubtaskInput };
