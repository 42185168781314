export const listProgressPercentage = (list) =>{
  const subtasks = list?.items || [];
  const total_length = subtasks?.length; 

  if(total_length === 0){
    return 0;
  }

  const number = subtasks.filter(item => item.done).length;

  const percentageValue = (number / total_length) * 100;

  return percentageValue.toFixed(0)
}