import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { listActions } from "../../actions";
import { listConstants } from "../../constants";
import { createCancelToken } from "../../utils/Axios";

const Interval_MS = 120000;

function ListHook({
  workspace_id,
  content_group_id
}) {
  const dispatch = useDispatch();
  const cancelTokenRef = useRef(createCancelToken());


  useEffect(() => {
    dispatch(listActions.getList({
                                  workspace_id, 
                                  content_group_id, 
                                  cancel_token: cancelTokenRef.current.token
                                }));

    const interval = setInterval(() => {
      dispatch(listActions.getList({
                                    workspace_id, 
                                    content_group_id, 
                                    cancel_token: cancelTokenRef.current.token
                                  }))
    }, Interval_MS);

    return () => {
      clearInterval(interval);

      cancelTokenRef.current.cancel('Operation canceled by the user');
      cancelTokenRef.current = createCancelToken();
      dispatch({ type: listConstants.LIST_CLEAR })
    };
  }, []);
  
  return null;
}

export { ListHook };
