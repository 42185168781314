import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function comment(state = initialState, action) {
  switch (action.type) {
    case userConstants.COMMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.UPDATE_COMMENT_LOADING: {
      return {
        ...state,
        update_comment_loading: true
      };
    }

    case userConstants.COMMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        draft_comment_loading: false,
        update_comment_loading: false,
        error:action.payload
      };
    }

    case userConstants.GET_COMMENT: {
      return {
        ...state,
        loading: false,
        update_comment_loading: false,
        comments: action.payload.data.data,
        can_access_comment: action.payload.data.can_access_comment,
        has_next_page: action.payload.data.has_next_page,
        addressComment: null,
        created_comment: null,
        isFocus: false,
      };
    }

    case userConstants.DRAFT_COMMENT_LOADING: {
      return {
        ...state,
        draft_comment_loading: true
      };
    }

    case userConstants.COMMENT_TAB_LOADED: {
      return {
        ...state,
        comment_tab_loading: false
      };
    }

    case userConstants.COMMENT_TAB_LOADING: {
      return {
        ...state,
        comment_tab_loading: true
      };
    }

    case userConstants.CREATE_DRAFT_COMMENT: {
      const draft_comment = action.payload.data.data;

      return {
        ...state,
        loading: false,
        draft_comment_loading: false,
        draft_comment: draft_comment,
      };
    }

    case userConstants.SETUP_DRAFT: {

      return {
        ...state,
        draft_comment_loading: false,
        partner_draft: action.payload.data.partner_draft,
        internal_draft: action.payload.data.internal_draft,
      };
    }

    case userConstants.UPDATE_DRAFT_COMMENT: {
      return {
        ...state,
        loading: false,
        draft_attachment_loading: false,
        draft_comment_loading: false,
      };
    }

    case userConstants.UPDATE_DRAFT_ATTACHMENT: {
      const draft_comment = action.payload.data.data;


      return {
        ...state,
        loading: false,
        draft_comment_loading: false,
        draft_attachment_loading: false,
        draft_comment: draft_comment
      };
    }

    case userConstants.UPDATE_DRAFT_ATTACHMENT_LOADING: {

      return {
        ...state,
        draft_attachment_loading: true,
      };
    }

    case userConstants.CLEAR_DRAFT: {
      return {
        ...state,
        loading: false,
        update_comment_loading: false,
        draft_comment: null
      };
    }

    case userConstants.CHAT_SCROLL_UPDATE: {
      return {
        ...state,
        scroll_update_comments: action.payload.data,
        loading: false,
      }
    }

    case userConstants.CHAT_SCROLL_INITIAL_LOAD: {
      return {
        ...state,
        scroll_initial_load: action.payload.data,
        update_comment_loading: false,
        loading: false,
      }
    }

    case userConstants.COMMENT_REQUEST: {
      return {
        ...state,
        loading: false,
        update_comment_loading: false
      };
    }

    case userConstants.CREATED_COMMENT: {
      return {
        ...state,
        created_comment: action.payload.data.comment,
        update_comment_loading: false,
        loading: false
      }
    }


    case userConstants.NEW_COMMENT: {
      let comment = action.payload
      const getMessage = [...state.comments, comment]
      return {
        ...state,
        comments: getMessage,
        comment: null,
        isFocus: true
      }
    }

    case userConstants.DELETE_COMMENT_ATTACHMENT:{
      return{
        ...state,
        deleted_comment_attachment: action.payload.data.data
      }
    }

    case userConstants.UPDATED_COMMENT:{
      return {
        ...state,
        updated_comment: action.payload.data.data,
        loading: false
      }
    }

    case userConstants.DELETE_COMMENT:{
      return {
        ...state,
        deleted_comment: action.payload.data.comment,
        loading: false
      }
    }
  default:
    return state
  }
}
