import { useContext } from "react";
import { 
  AddSubtaskListInput, 
  ListActionCable, 
  ListHook, 
  SubtaskList 
} from "./";
import { useDispatch, useSelector } from "react-redux";
import { ContentGroupContext } from "../../providers/ContentGroupProvider";

function SubtaskSection(props) {
  const dispatch = useDispatch();
  const { workspace_id, content_group_id } = useContext(ContentGroupContext);
  const lists = useSelector(state => state.list.lists);
  const can_create = useSelector(state => state.list.can_create);
  const can_show = useSelector(state => state.list.can_show);

  const filterList = () =>{
    if(can_show){
      return lists;
    }

    return lists.filter((list) => list.hidden === false)
  }

  return (
    <div className="subtask-section py-3">
      <ListActionCable />
      <ListHook 
        workspace_id={workspace_id} 
        content_group_id={content_group_id}
      /> 
      {
        lists &&
        filterList().map((list, index) => (
            <SubtaskList 
              list={list} 
              key={list.id} 
            />
          ))
      }
      {
        can_create &&
        <AddSubtaskListInput />
      }
    </div>
  );
}

export { SubtaskSection };
