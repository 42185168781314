import { createContext } from 'react';

export const ContentGroupContext = createContext();

export function ContentGroupProvider({ 
  children, 
  content_group_id,
  workspace_id
 }) {

  let values ={
    content_group_id,
    workspace_id
  }

  return (
    <ContentGroupContext.Provider value={values}>
      {children}
    </ContentGroupContext.Provider>
  )
}