import { update } from 'autosize';
import { listConstants } from '../constants';

const initialState = { loading: false, lists: [], error: '' };

export function list(state = initialState, action) {
  switch (action.type) {
    case listConstants.LIST_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    
    case listConstants.LIST_CREATED: {
      const newArray = [...state.lists.filter((item) => item.id !== action.payload.data.data.id)];

      return {
        ...state,
        lists: [...newArray, action.payload.data.data].sort((a, b) => (a.id > b.id) ? 1 : -1)
      };
    }

    case listConstants.LIST_UPDATED: {
      const updatedItem = action.payload.data.data;
      const newArray = [...state.lists]
      const index = newArray.findIndex(item => item.id === updatedItem.id);

      if(index === -1){
        return {
                ...state, 
                loading: false,
                lists: [...newArray, updatedItem].sort((a, b) => (a.id > b.id) ? 1 : -1)
              }
      }

      newArray[index] = updatedItem;

      return {
        ...state, 
        loading: false,
        lists: newArray.sort((a, b) => (a.id > b.id) ? 1 : -1)
      };
    }

    case listConstants.LIST_DELETED: {
      const deletedItem = action.payload.data.data;
      const newArray = state.lists.filter((list) => list.id !== deletedItem.id)

      return {
        ...state,
        lists: newArray.sort((a, b) => (a.id > b.id) ? 1 : -1)
      };
    }

    case listConstants.LIST_GET: {
      return {
        ...state,
        loading: false,
        lists: action.payload.data.data.lists,
        can_create: action.payload.data.data.can_create_list,
        can_update: action.payload.data.data.can_update_list,
        can_show: action.payload.data.data.can_view_all_list,
        can_destroy: action.payload.data.data.can_destroy_list
      };
    }

    case listConstants.LIST_ITEM_CREATED: {
      const createdListItem = action.payload.data.data;

      const newArray = state.lists.map(list => {
        if(list.id === createdListItem.list_id){
          return {
            ...list,
            items: [...list.items.filter((item) => item.id !== createdListItem.id), createdListItem].sort((a, b) => (a.id > b.id) ? 1 : -1)
          }
        }

        return list
      }).sort((a, b) => (a.id > b.id) ? 1 : -1)

      return {
        ...state,
        loading: false,
        lists: newArray
      };
    }

    case listConstants.LIST_ITEM_UPDATED: {
      const updatedListItem = action.payload.data.data;

      const newArray = state.lists.map(list => {
        if(list.id === updatedListItem.list_id){
          return {
            ...list,
            items: [...list.items.filter((item) => item.id !== updatedListItem.id), updatedListItem].sort((a, b) => (a.id > b.id) ? 1 : -1)
          }
        }

        return list
      }).sort((a, b) => (a.id > b.id) ? 1 : -1)

      return {
        ...state,
        loading: false,
        lists: newArray
      };
    }

    case listConstants.LIST_ITEM_DELETED: {
      const deletedListItem = action.payload.data.data;
      
      const newArray = state.lists.map(list => {
        if(list.id === deletedListItem.list_id){
          return {
            ...list,
            items: [...list.items.filter((item) => item.id !== deletedListItem.id)].sort((a, b) => (a.id > b.id) ? 1 : -1)
          }
        }

        return list
      }).sort((a, b) => (a.id > b.id) ? 1 : -1)

      return {
        ...state,
        loading: false,
        lists: newArray
      };
    }

    case listConstants.LIST_CLEAR: {
      return { ...initialState };
    }
  default:
    return state
  }
}
