import { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { Image } from '../../../../utils';
import { Row, Col, Button } from "react-bootstrap";
import { UserThumbnails, ContentLabel, CardTag, ProgressIcon } from '../../../../components';
import { adminBaseURL } from '../../../../utils/commonUtils';
import DueDateIcon from '../../../../images/icons/due-date.svg';
import LabelIcon from '../../../../images/icons/label.svg';
import ParticipantIcon from '../../../../images/icons/participant.svg';
import LoadiGif from "../../../../images/loading_image.gif";
import { dateTimeFormatter } from '../../../../utils';
import { content_groupActions } from '../../../../actions/';
import { WorkspaceShowContext } from "../../../../contexts/WorkspaceShowContext";
import { DraftCommentIcon } from '../../../../components';
import { hasDraft, isDraftHasValue } from '../../../../utils/isDraftHasValue';

function CardGroup(props) {
  const {
          item,
          index,
          can_crud_content_group,
          workspace_id,
          can_access_move_content_board,
          can_access_show_content_details,
          can_access_edit_label ,
          organization_id
        } = props;
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const replace_board = useSelector(state => state.content_group.replace_image);

  const {
    handleOpenContentGroupPopUp,
    handleOpenLabelDrpdwn,
    showLabelDrpdwn,
    setShowLabelDrpdwn,
    labelDrpdwnContainerRef,
    labelDrpdwnValues,
    setLabelDrpdwnValues
  } = useContext(WorkspaceShowContext);

  useEffect(() => {
    replace_board && replace_board.map((data, i) => {
      if(data && data.attachment) {
        var replaceImage = document.querySelector(`#find_div_board_${data.id} .kanban-card_image img`);
        if (!!replaceImage) {
          replaceImage.src = data.attachment.small.url;
        }
      }
    })
  }, [replace_board])

  const handleLabelDelete = (label_id, e) => {
    e.stopPropagation();

    let requestParams = { label_id: label_id, assigned: false }
    let callback = () => {}
    let content_popup_notification = undefined;

    dispatch(content_groupActions.assignContentLabelToContentGroup(workspace_id, item?.id, requestParams, content_popup_notification, callback))
  }

  let drpdwnContainerRef = useRef();

  useEffect(() => {
    if(item.id === labelDrpdwnValues.content_group_id){
      setLabelDrpdwnValues((prevState) => ({ ...prevState, labels: item?.labels }))
    }
  }, [item?.labels])

  const handleClickLabel = (e, item) => {
    if(!can_access_edit_label){
      return;
    }

    e.stopPropagation();
    let target_position = drpdwnContainerRef.current?.getBoundingClientRect();

    handleOpenLabelDrpdwn(drpdwnContainerRef.current, item.id, item.labels, labelDrpdwnContainerRef, showLabelDrpdwn, setShowLabelDrpdwn)
  }

  const has_draft = hasDraft(item?.drafts || []);
  const name_style = has_draft ? { maxWidth: `calc(100% - 20px)`, overflowWrap: 'break-word' } : {overflowWrap: 'break-word'}

  return (
    <Draggable
      key={"card_"+item.id}
      draggableId={"card-"+item.id}
      index={index}
      isDragDisabled={can_access_move_content_board ? false : true}
    >
      {(dragProvided, dragSnapshot) => {
        return (
          <div
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            className={"kanban-card" + (!item || !item.preview ? " kanban-card--no-preview" : "")}
            style={{
              userSelect: "none",
              minHeight: "50px",
              backgroundColor: "#fff",
              ...dragProvided.draggableProps.style,
            }}
          >
            <div className="kanban-card_header">
              <Row>
                <Col style={{ flexGrow: 1 }}>
                  <div className="d-flex justify-content-between">
                    <div className={` ${item.stage?.require_approval_from_client && item.stage?.submitted ? 'w-90' : 'w-100'}`}>
                      <CardTag
                        completed={item?.completed}
                        due_date={item?.due_date}
                        className={"mb-2"}
                        reminder_in_day={item?.reminder_in_day}/>
                      
                      <div
                        className='d-flex align-items-center'
                      >
                        <div
                          onClick={() => handleOpenContentGroupPopUp(item.id)}
                          className='w-100 word-break'
                        >
                          {item.name}
                          {has_draft && <DraftCommentIcon className={'ml-2'} />}
                          
                        </div>
                      </div>   
                    </div>

                    {
                      item.stage?.require_approval_from_client && item.stage?.submitted  &&
                        <ProgressIcon content_group={item}/>
                    }
                  </div>
                </Col>


              </Row>
            </div>
            <div
              data-image-pending={item.preview && item.preview.content_attachment && item.preview.content_attachment_type != "application/pdf" ? 'true' : 'false'}
              data-id={item.id}
              id={`find_div_board_${item.id}`}
              className="visible_board_div"
            >
              {item && item.preview && item.preview.content_attachment_type == "application/pdf" ?
                  <div className="kanban-card_image text-center">
                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                  </div>
                :
                  item.preview && item.preview.content_attachment &&
                    <div className="kanban-card_image">
                      <img src={LoadiGif }  alt="content" className="img-fluid"/>
                    </div>
              }
              <div className="kanban-card_body">
                { item && item.labels && item.labels.length > 0 &&
                  <div className="mb-1 d-flex align-items-center">
                    <img src={LabelIcon} height="14" className="attribute-icon label-icon" />
                    <div
                      ref={drpdwnContainerRef}
                    >
                      { item.labels.map((label, index) => (
                        <ContentLabel
                          id={label.id}
                          editable={can_access_edit_label}
                          name={label.name}
                          color_hex={label.color_hex}
                          key={index}
                          handleDelete={handleLabelDelete}
                          onClick={(e) => handleClickLabel(e, item) }
                          sharesPopup
                        />
                      )) }
                    </div>
                  </div>
                }
                <div className="kanban-card_body-date">
                  { item.due_date &&
                    <div className="d-flex align-items-center">
                      <img src={DueDateIcon} height="14" className="attribute-icon due_date-icon" />
                      { dateTimeFormatter(item.due_date) }
                    </div>
                  }
                </div>
                {can_access_show_content_details &&
                  <div className="d-flex align-items-center justify-content-between">
                    { item?.assigned_users?.length > 0 &&
                      <div className={ "kanban-card__participants-wrapper d-flex align-items-center " + ( item.assigned_users.length > 1 ? " kanban-card__participants-wrapper--full" : "" ) }>
                        <img src={ParticipantIcon} height="14" className="attribute-icon" />
                        <UserThumbnails
                          users={item.assigned_users}
                          openUserSummary ={true}
                          workspace_id={workspace_id}
                          organization_id={ organization_id }
                        />
                      </div>
                    }
                  </div>
                }

                <div className="d-none">
                  <i className="fa fa-comments mr-2" aria-hidden="true"></i>
                  {item.discussion_count}
                </div>

                <div className="d-none">
                  <i className="fa fa-sticky-note-o mr-2" aria-hidden="true"></i>
                  {item.internal_note_count}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}

export default CardGroup
