import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { notification } from './notification.reducer';
import { project } from './project.reducer';
import { content_label } from './content_label.reducer';
import { work_space } from './work_space.reducer';
import { multiUser } from './multi_user.reducer';
import { workspaceUser } from './workspace_user.reducer';
import { content } from './content.reducer';
import { content_group } from './content_group.reducer';
import { dashboard } from './dashboard.reducer';
import { user_profile } from './user_profile.reducer';
import { stage } from './stage.reducer';
import { template_stage } from './template_stage.reducer';
import { comment } from './comment.reducer';
import { chat } from './chat.reducer';
import { assigned_user } from './assigned_user.reducer';
import { search_history } from './search_history.reducer';
import { organization } from './organization.reducer';
import { list } from './list.reducer';

const rootReducer = combineReducers({
  content_label,
  authentication,
  registration,
  users,
  alert,
  notification,
  work_space,
  multiUser,
  workspaceUser,
  content,
  content_group,
  dashboard,
  user_profile,
  stage,
  template_stage,
  comment,
  chat,
  assigned_user,
  search_history,
  organization,
  list,
  form: formReducer
});

export default rootReducer;
