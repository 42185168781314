export const listConstants = {
  LIST_LOADING: 'LIST_LOADING',

  LIST_CREATED: 'Created list successfully',
  LIST_FAILURE: 'LIST_FAILURE',
  LIST_GET: 'LIST_GET', 
  LIST_UPDATED: 'Updated list successfully',
  LIST_DELETED: 'Deleted list successfully',

  LIST_CLEAR: 'LIST_CLEAR',

  LIST_ITEM_LOADING: 'LIST_ITEM_LOADING',
  LIST_ITEM_CREATED: 'Created subtask successfully',
  LIST_ITEM_UPDATED: 'Updated subtask successfully',
  LIST_ITEM_DELETED: 'Deleted subtask successfully',
  LIST_ITEM_FAILURE: 'LIST_ITEM_FAILURE',
};
