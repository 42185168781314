import ProgressBar from 'react-bootstrap/ProgressBar';
import { listProgressPercentage } from '../../utils/listProgressPercentage';
import { useMemo } from 'react';

function ListProgressBar({
  list
}) {
  const percentage = useMemo(() => listProgressPercentage(list), [list?.items]);

  return (
    <div className='mb-2 d-flex align-items-center justify-content-between subtask-progress-container'>
      <label className='mt-0'>{percentage}%</label>
      <div className='w-100'>
        <ProgressBar now={percentage}/>
      </div>
    </div>
  );
}

export { ListProgressBar };
