import { createContext, useEffect, useRef, useState } from 'react'
import { AudioContext, isSupported } from 'standardized-audio-context';

export const SoundContext = createContext();

export function AudioProvider({ children }) {
  const audioContext = useRef(null) ;
  const [audioContextReady, setAudioContextReady] = useState(false)
  const needSoundRef = useRef(false);

  const activateSound = () => {
    var new_context = new AudioContext();

    if (new_context) {
      new_context.resume().then(() => {
        setAudioContextReady(true)
        console.log('Activated sound')
        window.context = new_context
      }).catch(() => {
        window.context = new_context
      });
    }
  }

  useEffect(() => {
    var context = new AudioContext();
    window.context = context;

    isSupported().then((isSupported) => {
      if (isSupported) {
         // yeah everything should work
         document.body.addEventListener('click', activateSound );
      } else {
          // oh no this browser seems to be outdated
          alert('Browser seems to be outdated')
          
      }
    }).catch(() => {
      alert('The browser support could not be determined');
    });

    return () => {
      document.body.removeEventListener('click', activateSound );
    }
  },[]);

  let sound_values ={
    audioContextReady: audioContextReady,
  }

  return (
    <>
      <SoundContext.Provider value={sound_values}>
        {
          // Activates audio if user clicked anywhere inside website
          // Note: Elements with event.stopPropagation() may not activate this
        }
        {children}
      </SoundContext.Provider>
    </>
  )
}