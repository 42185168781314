import { useRef, useEffect, useState, useContext } from "react";
import { useDetectOutsideClick } from "../../utils/useDetectOutsideClick";
import { SubtaskIcon } from "../icons";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { listActions } from "../../actions/list.actions";
import { buildFormDataFromObject } from "../../utils/commonUtils";
import { ContentGroupContext } from "../../providers/ContentGroupProvider";

function AddSubtaskListInput() {
  const dispatch = useDispatch();
  const { workspace_id, content_group_id } = useContext(ContentGroupContext)
  const input_ref = useRef();
  const [input_active, setInputActive] = useDetectOutsideClick(input_ref, false);
  const [title, setTitle] = useState('');
  const title_ref = useRef('');

  function handleKeyPress(e){    
    if(['Enter'].includes(e.key)){
      setInputActive(false);
    }
  }

  useEffect(() => {
    if(!input_active && title_ref.current !== ''){

      handleSubmit()
        .then(() => {
          title_ref.current = '';
          setTitle('')
        });
    }

    if(input_active){
      if(input_ref.current){
        setTimeout(() => {
          input_ref.current.focus();
        }, 0);
      }
    }
    return () => {
      
    };
  }, [input_active]);

  useEffect(() => {
    title_ref.current = title;

    return () => {
      
    };
  }, [title]);

  async function handleSubmit(){
    let formData = new FormData();
    formData.title =  title_ref.current

    buildFormDataFromObject(formData, { title:  title_ref.current }, "list");

    return dispatch(listActions.createList(formData, workspace_id, content_group_id));
  }
  
  return (
    <>
      <div className={`subtask-form p-0 ${input_active ? "subtask-form-input_active" : "subtask-form-button"}`}>
        <InputGroup>
          <div className={`d-flex subtask-form-input ${input_active ? 'w-100' : ''}`}>
            <InputGroup.Text 
              className="subtask-form-icon"
              onClick={() => setInputActive(true)}
            >
              <SubtaskIcon/>
            </InputGroup.Text>
            <Form.Control
              placeholder="Add List Title"
              ref={input_ref}
              className={input_active ? '' : 'd-none'}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onKeyPress={handleKeyPress}
              type={'text'}
            />

            {
              !input_active &&
                <Button 
                  variant={'subtask'} 
                  className="btn-subtask-create"
                  onClick={() => setInputActive(true)}
                > 
                  Add List
                </Button>
            }
          </div>

        </InputGroup>
      </div>
    </>
  );
}

export { AddSubtaskListInput };
