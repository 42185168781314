import { useEffect, useState, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import ActionCable from 'actioncable';
import { ContentGroupContext } from '../../providers/ContentGroupProvider';
import { listConstants } from '../../constants';

function ListActionCable(props) {
  const dispatch = useDispatch();
  const { content_group_id } = useContext(ContentGroupContext)
  const [subscription, setSubscription] = useState(null);
  const cable = ActionCable.createConsumer(process.env.REACT_APP_CABLE_URL);
  
  const handleReceivedLists = useCallback( (res) => {    
    switch (res.data.type) {
      case 'create_list':
        dispatch({type: listConstants.LIST_CREATED, payload: res})
        break;
      case 'update_list':
        dispatch({type: listConstants.LIST_UPDATED, payload: res})
        break;
      case 'destroy_list':
        dispatch({type: listConstants.LIST_DELETED, payload: res})
        break;
      case 'create_list_item':
        dispatch({type: listConstants.LIST_ITEM_CREATED, payload: res})
        break;
      case 'update_list_item':
        dispatch({type: listConstants.LIST_ITEM_UPDATED, payload: res})
        break;
      case 'destroy_list_item':
        dispatch({type: listConstants.LIST_ITEM_DELETED, payload: res})
        break;
      default:
        console.error('Invalid list broadcast type')
    }
  }, []);

  useEffect(() => {
    let newSubscription = null;

    if(content_group_id){
      const channel = { channel: "ListChannel", content_group_id: content_group_id }
      newSubscription = cable.subscriptions.create(
        channel,
        {
          received: (res) => handleReceivedLists(res),
          connected: () => console.log('Connected to server.'),
          disconnected: () => console.log('Disconnected from ListChannel'),
        }
      );
  
      setSubscription(newSubscription);
    }

    return () => {
      // Unsubscribe when component unmounts or dependency changes
      if (newSubscription) {
        cable.subscriptions.remove(newSubscription);
        console.log('Unsubscribed from ListChannel');
      }
    };
  }, [content_group_id]);

  return null
}

export { ListActionCable };
