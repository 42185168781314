import { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../../constants';
import { reminderDisplayText } from '../../utils';
import {
  Modal,
  Button,
  NavDropdown,
  Dropdown,
  CloseButton,
  Image
} from 'react-bootstrap';
import CheckIcon from '../../images/icons/check.svg';
import DropdownIcon from '../../images/icons/dropdown-arrow.svg';

function ReminderInlineEdit(props) {
  const {
    content_group,
    updated_content_group,
    handleInlineEdit,
    scrollable,
  } = props;
  const [initial_loaded, setInitialLoaded] = useState(false);
  const options = [0,1,2,3,4,5,6,7];
  const [reminder_in_day, setReminderInDay] = useState(content_group?.reminder_in_day || 0);
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [dropdownStyle, setDropdownStyle] = useState({ bottom: 0, width: 0 });
  const parentRef = useRef();
  let parent_bounds = parentRef?.current?.getBoundingClientRect();

  useEffect(() => {
    if(content_group?.reminder_in_day !== content_group?.reminder_in_day){
      setReminderInDay(content_group?.reminder_in_day)
    }
  }, [content_group?.reminder_in_day]);

  useEffect(() => {
    if (parent_bounds){
      setDropdownStyle({width: parent_bounds?.width, bottom: parent_bounds?.bottom + 20 })
    }
  }, [parentRef?.current, parent_bounds?.bottom, parent_bounds?.width, scrollable]);

  useEffect(() => {
    if(initial_loaded === false){
      setInitialLoaded(true);
      return;
    }

    handleCloseMobilePopup();
    handleInlineEdit({reminder_in_day: reminder_in_day});

  }, [reminder_in_day]);

  const openMobilePopup = (show, meta) => {
    setShowMobilePopup(show);
  }

  const handleCloseMobilePopup = () => {
    setShowMobilePopup(false)
  }

  const getStyle = () => {
    if(isLargeScreen) return { display: 'none' };

    if (scrollable) {
      return {
        width: `${dropdownStyle.width}px`,
        maxHeight: `calc(100vh - 480px)`,
        maxHeight: `calc(100dvh - 480px)`,
        overflowY: 'auto'
      }
    } else {
      return {
        width: `${parent_bounds?.width}px`
      }
    }
  }

  return (
    <>
      <div className={`detail ${showMobilePopup && 'active'} bg-white`} ref={parentRef}>
        <Dropdown
          className="content__reminder-dropdown p-1 dropdown--with-check"
          onToggle={openMobilePopup}
        >
          <Dropdown.Toggle
            variant=""
            className={`text-left p-0 text-truncate w-100`}
          >
            { reminderDisplayText(reminder_in_day) }
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={getStyle()}
            className="reminder__dropdown vertical-scroll_thin">
              { options.map((opt, index) => (
                  <Dropdown.Item
                    key={'reminder_option_'+opt.id}
                    onClick={(e) => setReminderInDay(opt) }
                    className={'text-truncate'}>
                      { reminderDisplayText(opt, 'option') }

                      { opt === content_group?.reminder_in_day &&
                        <span className="dropdown__selected--with-check">
                          <img src={CheckIcon} alt="Selected" />
                        </span>
                      }
                  </Dropdown.Item>
                ))
              }
          </Dropdown.Menu>
        </Dropdown>
        <Image src={DropdownIcon} height={8} width={8} className="dropdown-icon"/>
      </div>

      <Modal
        show={showMobilePopup && isLargeScreen}
        onHide={handleCloseMobilePopup}
        keyboard={true}
        centered
        className="reminder__modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="font-weight-bold reminder__modal__title"
          >
            Reminder
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <ul className="reminder__modal__list vertical-scroll_thin modal-list">
            { options.map((opt, index) => (
                <li
                  key={'reminder_'+opt}
                  onClick={(e) => setReminderInDay(opt) }
                  className={'d-flex cursor-pointer justify-content-between text-truncate '}
                >
                  { reminderDisplayText(opt, "option") }

                  { opt === content_group?.reminder_in_day &&
                    <span className="dropdown__selected--with-check">
                      <img src={CheckIcon} alt="Selected" height="16"/>
                    </span>
                  }
                </li>
              ))
            }
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    updated_content_group: state.content_group.updated_content_group
  };
};

export default connect(mapStateToProps, { })(ReminderInlineEdit);
