import { alertActions } from './';
import { listConstants } from '../constants';
import { 
  makeGETRequest, 
  makeFormDataPOSTRequest, 
  makeDELETERequest, 
  makeFormDataPUTRequest, 
  controlledGETRequest, 
  createCancelToken 
} from '../utils/Axios';

export const listActions = {
  createList,
  getList,
  updateList,
  destroyList
};

export function createList(list_data, workspace_id, content_group_id) {
  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/lists`, { list: list_data })
      .then(response => {
        if(response.data.status === "ok"){
          //dispatch(alertActions.success(listConstants.LIST_CREATED));
          dispatch({ type: listConstants.LIST_CREATED, payload: response.data});
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: listConstants.LIST_FAILURE, payload: response.data.message})
        }
      })
    }catch(e){
      dispatch( {
        type: listConstants.LIST_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateList(props) {
  const {
    formData, 
    workspace_id, 
    content_group_id, 
    list_id,
  } = props;

  return function (dispatch) {
    try{
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/lists/${list_id}`, { list: formData })
      .then(response => {
        if(response.data.status === "ok"){
          //dispatch(alertActions.success(listConstants.LIST_UPDATED));
          dispatch({ type: listConstants.LIST_UPDATED, payload: response.data });
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: listConstants.LIST_FAILURE, payload: response.data.message});

          props?.onError();
        }
      })
    }catch(e){
      dispatch( {
        type: listConstants.LIST_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getList({
  workspace_id, 
  content_group_id,
  cancel_token = createCancelToken().token
}) {
  return function (dispatch) {
    try{
      controlledGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/lists`, cancel_token)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: listConstants.LIST_GET, payload: response});
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: listConstants.LIST_FAILURE, payload: response.data.message})
        }
      })
    }catch(e){
      dispatch( {
        type: listConstants.LIST_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyList(props) {
  const { workspace_id, content_group_id, list_id } = props;

  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/lists/${list_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          //dispatch(alertActions.success(listConstants.LIST_DELETED));
          dispatch({ type: listConstants.LIST_DELETED, payload: response.data });
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: listConstants.LIST_FAILURE, payload: response.data.message})
        }
      }).finally(() => {
        props?.onEnd?.();
      })
    }catch(e){
      dispatch( {
        type: listConstants.LIST_FAILURE,
        payload: console.log(e),
      })
    }
  }
}