import { userConstants, searchHistoryConstants } from '../constants';
import { removeNullInArray, addSearchHistory, removeSearchHistory } from '../utils';

const initialState = { loading: false, error: '' };

export function content_group(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.SEARCH_CONTENTGROUP_LOADING: {
      return {
        ...state,
        search_loading: true
      };
    }

    case userConstants.RECOMMEND_CONTENTGROUP_LOADING: {
      return {
        ...state,
        recommend_loading: true
      };
    }

    case userConstants.RECOMMEND_CONTENTGROUP: {
      return {
        ...state,
        recommends: action.payload.data.content_groups,
        recommend_loading: false
      };
    }

    case userConstants.RESET_RECOMMENDATION_CONTENTGROUP: {
      return {
        ...state,
        recommends: [],
        recommend_loading: false
      };
    }

    case userConstants.SEARCH_CONTENTGROUP: {
      let new_search_histories = state.search_histories ? addSearchHistory(state.search_histories, action.payload) : [action.payload]

      new_search_histories = removeNullInArray(new_search_histories)

      localStorage.setItem(searchHistoryConstants.KEY, JSON.stringify(new_search_histories));

      return {
        ...state,
        search_histories: new_search_histories,
        currentSearchText: action.payload.text
      };
    }

    case userConstants.DELETE_SEARCH_CONTENTGROUP: {
      let new_search_histories = removeSearchHistory(state.search_histories, action.payload)
      localStorage.setItem(searchHistoryConstants.KEY, JSON.stringify(new_search_histories));

      return {
        ...state,
        search_histories: new_search_histories
      };
    }

    case userConstants.SET_SEARCH_HISTORY_CONTENTGROUP: {
      let new_search_histories = state.search_histories ? [...state.search_histories, ...action.payload] : action.payload

      new_search_histories = removeNullInArray(new_search_histories)

      return {
        ...state,
        search_histories: new_search_histories
      };
    }

    case userConstants.CONTENTGROUP_FAILURE: {
      return {
        ...state,
        error:action.payload,
        content_group_loading: false,
        search_loading: false,
        loading: false
      };
    }

    case userConstants.CONTENTGROUP_REQUEST: {
      return {
        ...state,
        loading: false,
        content_group_loading: false
      };
    }

    case userConstants.CREATED_CONTENTGROUP: {
      return {
        ...state,
        loading: false,
        content_group_loading: false,
        created_content_group: action.payload.data.content_group
      };
    }


    case userConstants.UPDATE_DRAFT_STATUS_CONTENTGROUP: {
      const { id:content_group_id, stage_id } = action.payload.data.content_group;
      const { partner_draft, internal_draft } = action.payload.data;
      const draft_status =  { content_group_id, stage_id, partner_draft, internal_draft };

      return {
        ...state,
        draft_status: draft_status
      };
    }
    case userConstants.DESTROYED_CONTENTGROUP: {
      return {
        ...state,
        loading: false,
        content_group_loading: false,
        destroyed_content_group: action.payload.data.data
      };
    }

    case userConstants.UPDATE_CONTENTGROUP: {
      return {
        ...state,
        loading: false,
        content_group_loading: false,
        updated_content_group: action.payload.data.data
      };
    }

    case userConstants.GET_CONTENTGROUP:{
      return{
        ...state,
        loading: false,
        content_group_loading: false
      }
    }

    case userConstants.SUBMIT_CONTENTGROUP:{
      return{
        ...state,
        loading: false
      }
    }

    case userConstants.VOID_SUBMIT_CONTENTGROUP:{
      return{
        ...state,
        loading: false
      }
    }

    case userConstants.APPROVE_CONTENTGROUP:{
      return{
        ...state,
        loading: false
      }
    }

    case userConstants.REJECT_CONTENTGROUP:{
      return{
        ...state,
        loading: false
      }
    }

    case userConstants.NEW_CONTENTGROUP:{
      return{
        ...state,
        labels: action.payload.data.labels,
        currentWorkSpace: action.payload.data.workspace,
        stages: action.payload.data.stages,
        loading: false,
        content_group_loading: false
      }
    }

    case userConstants.EDIT_CONTENTGROUP:{
      return {
        ...state,
        editContentGroup: {data: action.payload.data.data, status: action.payload.data.status},
        labels: action.payload.data.labels,
        currentWorkSpace: action.payload.data.workspace,
        stages: action.payload.data.stages,
        loading: false,
        content_group_loading: false
      }
    }

    case userConstants.CONTENTGROUP_LOADING:{
      return {
        ...state,
        content_group_loading: true
      }
    }

    case userConstants.DELETE_CONTENTGROUP:{
      let id = action.payload.data.data.id
      return {
        ...state,
        content_groups: state.content_groups.filter(item => item.id !== id),
        loading: false
      }
    }

   case userConstants.REPLACE_CONTENT_IMAGE: {
     return {
       ...state,
       replace_image: action.payload.data.data
     }
   }

  default:
    return state
  }
}
