import { useState } from "react";
import { useDetectOutsideClick } from "../utils/useDetectOutsideClick";

export const useDetectOutsideDoubleClick = ( 
  enableFunction,
  input_ref,
  delay_ms = 251
) => {
  const [waitingClick, setWaitingClick] = useState(null);
  const [lastClick, setLastClick] = useState(0);
  const [isActive, setIsActive] = useDetectOutsideClick(input_ref, false)

  const doubleClick = (e) => {
    if(lastClick &&e.timeStamp - lastClick < 250 && waitingClick){
      //Double Click

      setLastClick(0);
      clearTimeout(waitingClick);
      setWaitingClick(null);
      if(!isActive) {
        setIsActive(true)
        enableFunction(e);
      }

      e.preventDefault();
    }
    else{
      //Single Click

      setLastClick(e.timeStamp);
      setWaitingClick(setTimeout(()=>{
        setWaitingClick(null);
      }, delay_ms))
    }
  }

  return { isActive, setIsActive, doubleClick }
};