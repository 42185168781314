import { useState, useRef, useEffect, useCallback } from 'react';
import { mediaQueryConstants } from '../constants';
import { useMediaQuery } from 'react-responsive';

function ContentGroupInlineEdit(props) {
  const { content_group_data, can_access_content_update,  } = props
  const [ editMode, setEditMode ] = useState(false);
  const [ content, setContent] = useState(content_group_data);
  const [ width, setWidth] = useState(0);
  const [ height, setHeight ]= useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [waitingClick, setWaitingClick] = useState(null);
  const [lastClick, setLastClick] = useState(0);
  const span = useRef();
  const input = useRef();
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` })

  useEffect(() => {
   setContent(content_group_data)
  }, [content_group_data]);

  useEffect(() => {
    if(span?.current?.offsetWidth){
      setWidth(span?.current?.offsetWidth + 14)
    }
  }, [span?.current?.offsetWidth]);

  useEffect(() => {
    if(input?.current?.offsetHeight){
      setHeight(input?.current?.offsetHeight + 12)
    }
  }, [input?.current?.offsetHeight]);

  const processClick = (e) => {
    if(lastClick &&e.timeStamp - lastClick < 250 && waitingClick){
      //Double Click

      setLastClick(0);
      clearTimeout(waitingClick);
      setWaitingClick(null);
      if(isDisabled) {
        handleFocus(e);
      }

      e.preventDefault();
    }
    else{
      //Single Click

      setLastClick(e.timeStamp);
      setWaitingClick(setTimeout(()=>{
        setWaitingClick(null);
      }, 251))
    }
  }

  const handleFocus = (e) => {
    setIsDisabled(false);
    input.current.focus();

    setTimeout(() => {
      // Get the mouse position relative to the editable div
       const rect = input.current.getBoundingClientRect();
       const posX = e.clientX - rect.left;
       const posY = e.clientY - rect.top;

       const selection = window.getSelection();
       const range = document.createRange();

       // Get the text node from the editable div
       const textNode = input.current.childNodes[0];

       if (textNode) {
         range.setStart(textNode, 0);
         range.collapse(true); // Collapse the range to the start

         // Iterate over the text nodes to find the correct position
         let charIndex = 0;
         for (let i = 0; i < textNode.length; i++) {
           range.setStart(textNode, i);
           range.setEnd(textNode, i + 1);
           const rangeRect = range.getBoundingClientRect();

           // Check if the click is within the bounds of the character
           if (
             posX >= rangeRect.left - rect.left &&
             posX <= rangeRect.right - rect.left &&
             posY >= rangeRect.top - rect.top &&
             posY <= rangeRect.bottom - rect.top
           ) {
             charIndex = i; // Set the character index to place the cursor
             break;
           }
         }

         // Set the final range based on character index
         range.setStart(textNode, charIndex);
         range.setEnd(textNode, charIndex);
         selection.removeAllRanges();
         selection.addRange(range);
       }
    }, 0);
  };

  const onKeyDown = (event) => {
    if (event.key === "Escape") {
      setContent(content_group_data);
      setEditMode(false);
    }

    if (event.key === "Enter") {
      event.target.blur();
    }

    event.stopPropagation();
  }

  const setFocused = () => { };

  const handleInputOnChange = (event) => setContent(event.target.value)

  const handleOnBlurInput = (e) => {
    setIsDisabled(true);

    if(e.target.innerText.trim() !== '' && e.target.innerText !== content_group_data) {
      props.handleInlineEdit(setFocused, {name: e.target.innerText})
      setEditMode(false)
    }else {
      e.target.innerText = content_group_data
      setContent(content_group_data)
      setEditMode(false)
    }
  }

  const onInput = (target) => {
    if (target?.scrollHeight > 33) {
      target.style.height = "5px";
      target.style.height = target.scrollHeight + 5 + "px";
    }
  };

  useEffect(() => {
    if(input){
      //onInput(input.current);
    }
  }, [onInput, input]);

  return (
    <>
    {
      can_access_content_update ?
        <div
          contentEditable={!isDisabled}
          type="text"
          ref={input}
          value={content}
          onKeyDown={onKeyDown}
          onChange={handleInputOnChange}
          onInput={(event) => onInput(event.target)}
          placeholder="Edit title"
          onBlur={handleOnBlurInput}
          style={{
            padding: '5px',
            userSelect: 'none'
          }}
          onClick={processClick}
          onMouseDown={(event) => {
            if (event.detail > 1) {
              //event.preventDefault();
              // Prevent text select at double click
            }
          }}
          className="inline_edit_input mt-lg-1 d-block break-word disableable vertical-scroll_thin hidden-scroll m-auto h4 w-100 font-weight-bold card_title-height"
        >
          {content}
        </div>
      :
        <h1
          className="h4 w-100 mb-0 font-weight-bold position-relative"
          style={{}}
        >
          <div
            className={"card_title-height hidden-scroll vertical-scroll_thin break-word"}
          >
            {content}
          </div>
        </h1>
    }

    </>
  );
}

export { ContentGroupInlineEdit };
