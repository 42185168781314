import { Image, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { UserAvatar } from '../components';

function UserThumbnails(props) {
  const {
          users,
          limit,
          disable_users_info,
          tooltip,
          openPopup,
          workspace_id,
          organization_id,
          openUserSummary,
          className
        } = props
  const [show, setShow] = useState(false);

  const tooltipHandler = (name) => {
    return {
      'data-toggle': "tooltip",
      title: name
    }
  }

  const expandedUsers = (users && limit && users.length > limit) ? users.slice(0, limit) : users
  const collapsedUsers = (users && limit) ? users.slice(limit, users.size) : []

  // WORKAROUND: The child items are reversed so that we can apply negative margin and have the
  // first user overlap over the second item, and not the other way around, without using z-index.
  // Rendering of items are reversed through "flex-direction: row-reverse".
  return (
    <div className={`${className} user-thumbnails`}>
      { users &&
        <>
          {
            Array.from(expandedUsers).map((user, index) =>(
              <div
                className={`custom-tooltip ${index === Array.from(expandedUsers).length - 1 ? 'last-user' :  'mr-1'} `}
                key={ 'user_thumbnail_'+user?.id}
                {...tooltipHandler(user?.name)}
                style={{zIndex: expandedUsers.length - index}}
              >
                <UserAvatar
                  icon={ user?.logo?.icon?.url ? user.logo.icon.url : null }
                  name={ user?.name }
                  name_initials={ user?.name_initials }
                  user_id={ user?.id }
                  size="30"
                  workspace_id={workspace_id}
                  organization_id={organization_id}
                  openUserSummary={openUserSummary}
                 />
              </div>
            ))
          }

          { collapsedUsers && collapsedUsers.length > 0 &&
            <div className="more-users">
              <div className="btn-more_participants" onClick={() => disable_users_info == undefined && setShow(true)}>
                +{collapsedUsers.length}
              </div>
            </div>
          }

          {
            openPopup !== undefined && openPopup !== null &&
              <div className="more-users">
                <div className="btn-more_participants bg-white" onClick={openPopup}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </div>
              </div>
          }

        </>
      }

      <Modal
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Participants</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="more-users_panel">
            <ul>
            { users &&
              users.map((user, index) =>(
                <li className="" key={index}>
                  <UserAvatar icon={ user?.logo?.url ? user.logo.url : null } name={ user?.name } name_initials={ user?.name_initials } size="30" />
                  {user?.name}
                </li>
              ))
            }
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { UserThumbnails };
