function SubtaskIcon({ 
  className, 
  color = "#727580", 
  height = 14, 
  width = 20 
}) {

  return (
    <svg 
      className={className}
      width={ width ? width : "24" }
      height={ height ? height : "24" }
      viewBox="0 0 20 14" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M3.04284 11.6197L6.52738 8.13517C6.72369 7.93886 6.95272 7.8448 7.21447 7.85298C7.47622 7.86116 7.70525 7.9634 7.90156 8.15971C8.08152 8.35603 8.17149 8.58506 8.17149 8.84681C8.17149 9.10856 8.08152 9.33759 7.90156 9.5339L3.75447 13.7055C3.55816 13.9018 3.32913 14 3.06738 14C2.80563 14 2.5766 13.9018 2.38028 13.7055L0.269929 11.5952C0.0899764 11.4152 0 11.1862 0 10.9081C0 10.63 0.0899764 10.4009 0.269929 10.221C0.449882 10.041 0.678913 9.95106 0.957022 9.95106C1.23513 9.95106 1.46416 10.041 1.64411 10.221L3.04284 11.6197ZM3.04284 3.76723L6.52738 0.282689C6.72369 0.0863773 6.95272 -0.00768889 7.21447 0.00049078C7.47622 0.00867045 7.70525 0.110916 7.90156 0.307229C8.08152 0.503541 8.17149 0.732571 8.17149 0.994321C8.17149 1.25607 8.08152 1.4851 7.90156 1.68141L3.75447 5.85305C3.55816 6.04936 3.32913 6.14751 3.06738 6.14751C2.80563 6.14751 2.5766 6.04936 2.38028 5.85305L0.269929 3.74269C0.0899764 3.56274 0 3.33371 0 3.0556C0 2.77749 0.0899764 2.54846 0.269929 2.36851C0.449882 2.18855 0.678913 2.09858 0.957022 2.09858C1.23513 2.09858 1.46416 2.18855 1.64411 2.36851L3.04284 3.76723ZM11.3616 12.4295C11.0835 12.4295 10.8503 12.3354 10.6622 12.1473C10.4741 11.9592 10.38 11.7261 10.38 11.4479C10.38 11.1698 10.4741 10.9367 10.6622 10.7486C10.8503 10.5604 11.0835 10.4664 11.3616 10.4664H18.2325C18.5106 10.4664 18.7437 10.5604 18.9319 10.7486C19.12 10.9367 19.2141 11.1698 19.2141 11.4479C19.2141 11.7261 19.12 11.9592 18.9319 12.1473C18.7437 12.3354 18.5106 12.4295 18.2325 12.4295H11.3616ZM11.3616 4.57702C11.0835 4.57702 10.8503 4.48295 10.6622 4.29482C10.4741 4.10669 10.38 3.87357 10.38 3.59546C10.38 3.31735 10.4741 3.08423 10.6622 2.89609C10.8503 2.70796 11.0835 2.6139 11.3616 2.6139H18.2325C18.5106 2.6139 18.7437 2.70796 18.9319 2.89609C19.12 3.08423 19.2141 3.31735 19.2141 3.59546C19.2141 3.87357 19.12 4.10669 18.9319 4.29482C18.7437 4.48295 18.5106 4.57702 18.2325 4.57702H11.3616Z" 
        fill={color}
      />
    </svg>

  );
}

export { SubtaskIcon };
